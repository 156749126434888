import React, {useCallback, useState} from 'react';
import {useRouteMatch} from 'react-router';

import PageHeading from '../styled/PageHeading';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Loader from '../Loader';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import PageContainer from '../styled/PageContainer';
import SearchIcon from '../Icons/SearchIcon';
import FileLoader from '../FileLoader';
import Group from '../Structure/Group';
import {tableFields} from './components';
import {API_getObjectMooringCertificates} from '../../utils/api';
import {getObjectIdFromRoute} from '../../utils/getObjectIdFromRoute';
import {setProperties} from '../../utils/setProperties';
import {prepareFiles} from '../../utils/prepareFiles';
import PageConstructor from '../PageConstructor';
import EditUShModal from '../Forms/EditUShModal';
import Icon from "../Icon";
import FilterIcon from "../Icons/FilterIcon";
import Filters from "../Filters";
import {FilterWrapper, InputWrapper, Statistics} from "../GroupContent";
import {useModal} from "../../hooks/useModal";
import NewPresentation from "../Forms/NewPresentation";
import {prepareExportUrl} from '../../utils/prepareExportUrl';
import {DEFAULT_FILTERS_DATA} from '../../utils/filtersHelpers';
import {preparePresentations} from '../../utils/preparePresentations';
import {prepareDateToSee} from '../../utils/dates';
import {useSelector} from "react-redux";
import StatItem from "../StatItem";
import styled from "styled-components";

const searchIcons = [
    {
        id: 0,
        component: SearchIcon,
    },
];

const USh = (props) => {
    const {allMooringCertificates} = props;
    const {isArchive} = useSelector(state => state.breadcrumbs);

    const route = useRouteMatch();
    const objectId = getObjectIdFromRoute(route);
    const [filtersDataLocal, setFiltersDataLocal] = useState(DEFAULT_FILTERS_DATA);

    const prepareRecievedItemsFunction = useCallback(allItems => {
        const todayDate = Number(new Date());

        return allItems.map((item) => {
            const {
                id,
                name,
                number,
                current_documentation,
                closing_date_planned,
                closing_date_real,
                files: filesData,
                presentations,
            } = item;

            const presentationsList = preparePresentations([...presentations].reverse());
            const filesList = prepareFiles(filesData);
            const datePlan = closing_date_planned?.date;
            const dateReal = closing_date_real?.date;

            const propertiesItem = setProperties({
                id: {value: id},
                name: {value: name},
                number: {value: number},
                current_documentation: {value: current_documentation},
                closing_date_planned: {value: datePlan, isDate: true},
                closing_date_real: {value: dateReal, isDate: true},
                presentations: {value: presentationsList, isList: true},
                files: {value: filesList, isFile: true},
            });

            if (!dateReal && datePlan && Number(new Date(datePlan)) <= todayDate) {
                propertiesItem._systemSettings = {colored: true};
            }

            return propertiesItem;
        });
    }, []);

    const requestUrl = API_getObjectMooringCertificates([{
        column: 'contract_object_id',
        operator: '=',
        value: objectId,
    }]);

    const [showPresentationModal] = useModal({
        component: NewPresentation,
        name: 'new-presentation',
        title: 'Редактирование предъявлений'
    });

    const addPresentation = [
        {
            label: 'Редактировать предъявления',
            onClick: (item) => showPresentationModal({data: {item, modelType: 'mooring_certificates'}})
        }
    ];

    const getTemplate = (props) => {
        const {
            searchValue,
            handleSearchValue,
            isLoading,
            filteredRecievedItems,
            showModal,
            onSort,
            sort,

            onFileImport,

            isFiltersVisible,
            toggleFilters,
            onFiltersSubmit,
            setFiltersData,
            filtersData,
        } = props;

        const onFileImportHandler = (files) => {
            onFileImport(files, API_getObjectMooringCertificates());
        };

        setFiltersDataLocal(filtersDataLocal);

        let totalUshCount =0;
        let closedUshCount = 0;
        let overdueUshCount = 0;

        if(filteredRecievedItems.length){
            totalUshCount = filteredRecievedItems.length;
            closedUshCount = filteredRecievedItems.filter(item => Boolean(item.closing_date_real.value)).length;
            overdueUshCount = filteredRecievedItems.filter(item => (!(item.closing_date_real.value) && +(new Date(item.closing_date_planned.value)) < +(new Date())) || (!!(item?.closing_date_real?.value) && !!(item?.closing_date_planned?.value) && item.closing_date_planned.value < item.closing_date_real.value)).length;
        }


        return (
            <PageContainer>
                <PageHeading>
                    <SearchWrapper>
                        <InputWrapper>
                            <Input
                                icon={searchIcons}
                                placeholder='Поиск по УШ'
                                name='search'
                                value={searchValue}
                                onChange={handleSearchValue}
                            />
                        </InputWrapper>
                        <FilterWrapper>
                            <Icon icon={FilterIcon} onClick={toggleFilters}/>
                            {isFiltersVisible && <Filters
                                handleHide={toggleFilters}
                                filtersData={filtersData}
                                onFiltersSubmit={onFiltersSubmit}
                                setFiltersData={setFiltersData}
                                DEFAULT_FILTERS_DATA={DEFAULT_FILTERS_DATA}
                            />}
                        </FilterWrapper>
                        <Statistics>
                            <StatItem label={'Количество УШ'} value={totalUshCount} />
                            <StatItem label={'Закрытые УШ'} value={closedUshCount} />
                            <StatItem label={'Просроченные УШ'} value={overdueUshCount} />
                        </Statistics>
                    </SearchWrapper>
                </PageHeading>
                <PageContent>
                    <Loader isLoading={isLoading}/>
                    {filteredRecievedItems.length ?
                        <Table disabled={isArchive} addBetween={false} onSort={onSort} sort={sort} fields={tableFields}
                               data={filteredRecievedItems} controlsList={addPresentation} modalType='ush'/> : null}
                </PageContent>
                <PageFooter>
                    <Group>
                        {
                            !isArchive && <Button label='Добавить новую запись' onClick={showModal}/>
                        }
                        <FileLoader label='Импорт из Excel' onLoad={onFileImportHandler} multiple={false}/>
                    </Group>
                </PageFooter>
            </PageContainer>
        );
    };

    const getExportRequestUrlWithFilters = () => {
        const {no_closed_dates_checkbox, date_before, date_from} = filtersDataLocal;

        const filtersArray = [{
            column: 'contract_object_id',
            operator: '=',
            value: objectId,
        }];

        if (no_closed_dates_checkbox.value) {
            filtersArray.push({
                column: 'closing_date_real',
                operator: '=',
                value: '',
            });
        }

        if (date_from.value) {
            filtersArray.push({
                column: 'closing_date_planned',
                operator: '>=',
                value: prepareDateToSee(date_from.value),
            });
        }

        if (date_before.value) {
            filtersArray.push({
                column: 'closing_date_planned',
                operator: '<=',
                value: prepareDateToSee(date_before.value),
            });
        }

        return API_getObjectMooringCertificates(filtersArray);
    };

    const exportRequestUrl = getExportRequestUrlWithFilters();

    const EXPORT_MODULE_URL = prepareExportUrl(exportRequestUrl, [
        ...tableFields.filter(({name}) => {
            const EXCLUDED_FIELDS = ['presentations', 'files'];

            return !EXCLUDED_FIELDS.includes(name);
        }),
        {name: 'presentations.date', label: 'Дата предъявления'},
        {name: 'presentations.note', label: 'Замечания к предъявлению'},
        {name: 'files.name', label: 'Фотограции и другие необходимые файлы'},
    ]);

    const UShProps = {
        FILTERED_SEARCH_PROPERTIES: [
            'name',
            'number',
            'current_documentation',
            'closing_date_real',
            'closing_date_planned',
        ],

        RECIEVED_DATA_ITEMS: allMooringCertificates,

        isSortableTable: true,

        modalProps: {
            component: EditUShModal,
            name: 'new-ush',
            title: 'Новая запись закрытия УШ',
            props: {objectId},
        },

        IS_FILTERS_ENABLED: true,

        EXPORT_MODULE_NAME: 'ush',
        EXPORT_MODULE_URL,

        prepareRecievedItemsFunction,

        requestUrl,

        getTemplate,
    };

    return <PageConstructor {...UShProps} />;
};

export default USh;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
