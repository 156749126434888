import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router';

import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Table from '../Table';
import { tableFields } from './components';
import PageFooter from '../styled/PageFooter';
import Group from '../Structure/Group';
import Button from '../Button';
import FileLoader from '../FileLoader';
import PageContainer from '../styled/PageContainer';
import { getObjectIdFromRoute } from '../../utils/getObjectIdFromRoute';
import Loader from '../Loader';
import { setProperties } from '../../utils/setProperties';
import { API_getObjectTravelingCertificates } from '../../utils/api';
import { prepareFiles } from '../../utils/prepareFiles';
import PageConstructor from '../PageConstructor';
import { searchIcons } from '../BankGuarantees/components';
import EditUHModal from '../Forms/EditUHModal';
import {FilterWrapper, InputWrapper, Statistics} from "../GroupContent";
import Icon from "../Icon";
import FilterIcon from "../Icons/FilterIcon";
import Filters from "../Filters";
import { useModal } from "../../hooks/useModal";
import NewPresentation from "../Forms/NewPresentation";
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import { DEFAULT_FILTERS_DATA } from '../../utils/filtersHelpers';
import { preparePresentations } from '../../utils/preparePresentations';
import { prepareDateToSee } from '../../utils/dates';
import {useSelector} from "react-redux";
import StatItem from "../StatItem";

const UH = (props) => {
  const { allTravelingCertificates } = props;
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const route = useRouteMatch();
  const objectId = getObjectIdFromRoute(route);
  const [filtersDataLocal, setFiltersDataLocal] = useState(DEFAULT_FILTERS_DATA);

  const prepareRecievedItemsFunction = useCallback(allItems => {
    const todayDate = Number(new Date());

    return allItems.map((item) => {
      const {
        id,
        name,
        number,
        current_documentation,
        closing_date_planned,
        closing_date_real,
        files: filesData,
        presentations,
      } = item;

      const presentationsList = preparePresentations([...presentations].reverse());
      const filesList = prepareFiles(filesData);
      const datePlan = closing_date_planned?.date;
      const dateReal = closing_date_real?.date;

      const propertiesItem = setProperties({
        id: { value: id },
        name: { value: name },
        number: { value: number, isNumber: true },
        current_documentation: { value: current_documentation },
        closing_date_planned: { value: datePlan, isDate: true },
        closing_date_real: { value: dateReal, isDate: true },
        presentations: { value: presentationsList, isList: true },
        files: { value: filesList, isFile: true },
      });

      if (!dateReal && datePlan && Number(new Date(datePlan)) <= todayDate) {
        propertiesItem._systemSettings = { colored: true };
      }

      return propertiesItem;
    });
  }, []);

  const requestUrl = API_getObjectTravelingCertificates([{
    column: 'contract_object_id',
    operator: '=',
    value: objectId,
  }]);

  const [showPresentationModal] = useModal({
    component: NewPresentation,
    name: 'new-presentation',
    title: 'Редактирование предъявлений'
  });

  const addPresentation = [
    {
      label: 'Редактировать предъявления',
      onClick: (item) => showPresentationModal({ data: { item, modelType: 'traveling_certificates' } })
    }
  ];

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onSort,
      sort,
      onFileImport,

      isFiltersVisible,
      toggleFilters,
      onFiltersSubmit,
      setFiltersData,
      filtersData,
    } = props;

    const onFileImportHandler = (files) => {
      onFileImport(files, API_getObjectTravelingCertificates());
    };

    setFiltersDataLocal(filtersData);

    let totalUhCount =0;
    let closedUhCount = 0;
    let overdueUhCount = 0;

    if(filteredRecievedItems.length){
      totalUhCount = filteredRecievedItems.length;
      closedUhCount = filteredRecievedItems.filter(item => Boolean(item.closing_date_real.value)).length;
      overdueUhCount = filteredRecievedItems.filter(item => (!(item.closing_date_real.value) && +(new Date(item.closing_date_planned.value)) < +(new Date())) || (!!(item?.closing_date_real?.value) && !!(item?.closing_date_planned?.value) && item.closing_date_planned.value < item.closing_date_real.value)).length;
    }

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <InputWrapper>
              <Input
                icon={searchIcons}
                placeholder='Поиск по УХ'
                name='search'
                value={searchValue}
                onChange={handleSearchValue}
              />
            </InputWrapper>
            <FilterWrapper>
              <Icon icon={FilterIcon} onClick={toggleFilters} />
              {isFiltersVisible && <Filters
                handleHide={toggleFilters}
                filtersData={filtersData}
                onFiltersSubmit={onFiltersSubmit}
                setFiltersData={setFiltersData}
                DEFAULT_FILTERS_DATA={DEFAULT_FILTERS_DATA}
              />}
            </FilterWrapper>
            <Statistics>
              <StatItem label={'Количество УШ'} value={totalUhCount} />
              <StatItem label={'Закрытые УШ'} value={closedUhCount} />
              <StatItem label={'Просроченные УШ'} value={overdueUhCount} />
            </Statistics>
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} addBetween={false} onSort={onSort} sort={sort} fields={tableFields} data={filteredRecievedItems} controlsList={addPresentation} modalType='uh' /> : null}
        </PageContent>
        <PageFooter>
          <Group>
            {
              !isArchive &&<Button label='Добавить новую запись' onClick={showModal} />
            }
            <FileLoader label='Импорт из Excel' onLoad={onFileImportHandler} multiple={false}/>
          </Group>
        </PageFooter>
      </PageContainer>
    );
  };

  const getExportRequestUrlWithFilters = () => {
    const { no_closed_dates_checkbox, date_before, date_from } = filtersDataLocal;

    const filtersArray = [{
      column: 'contract_object_id',
      operator: '=',
      value: objectId,
    }];

    if (no_closed_dates_checkbox.value) {
      filtersArray.push({
        column: 'closing_date_real',
        operator: '=',
        value: '',
      });
    }

    if (date_from.value) {
      filtersArray.push({
        column: 'closing_date_planned',
        operator: '>=',
        value: prepareDateToSee(date_from.value),
      });
    }

    if (date_before.value) {
      filtersArray.push({
        column: 'closing_date_planned',
        operator: '<=',
        value: prepareDateToSee(date_before.value),
      });
    }

    return API_getObjectTravelingCertificates(filtersArray);
  };

  const exportRequestUrl = getExportRequestUrlWithFilters();

  const EXPORT_MODULE_URL = prepareExportUrl(exportRequestUrl, [
    ...tableFields.filter(({ name }) => {
      const EXCLUDED_FIELDS = ['presentations', 'files'];

      return !EXCLUDED_FIELDS.includes(name);
    }),
    { name: 'presentations.date', label: 'Дата предъявления' },
    { name: 'presentations.note', label: 'Замечания к предъявлению' },
    { name: 'files.name', label: 'Фотограции и другие необходимые файлы' },
  ]);

  const UHProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'name',
      'number',
      'current_documentation',
      'closing_date_planned',
      'closing_date_real',
    ],

    isSortableTable: true,

    RECIEVED_DATA_ITEMS: allTravelingCertificates,

    modalProps: {
      component: EditUHModal,
      name: 'new-uh',
      title: 'Новая запись закрытия УХ',
      props: { objectId },
    },

    IS_FILTERS_ENABLED: true,

    EXPORT_MODULE_NAME: 'uh',
    EXPORT_MODULE_URL,

    prepareRecievedItemsFunction,

    requestUrl,

    getTemplate,
  };

  return <PageConstructor {...UHProps} />;
};

export default UH;