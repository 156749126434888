import React, { useCallback } from 'react';
import styled from 'styled-components';

import { searchIcons, tableFields } from './components';
import EditBankGuaranteeModal from '../Forms/EditBankGuaranteeModal';
import { useRouteMatch } from 'react-router';
import { API_getBankGuarantees } from '../../utils/api';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { setProperties } from '../../utils/setProperties';
import PageConstructor from '../PageConstructor';
import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Loader from '../Loader';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const BankGuarantees = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const prepareRecievedItemsFunction = useCallback((bankGuarantees) => {
    return bankGuarantees.map(guarantees => {
      const { id, amount, date, number, note, last_modified_date, issued_by, validity } = guarantees;

      const guaranteesItem = setProperties({
        id: { value: id },
        number: { value: number },
        issued_by: { value: issued_by },
        note: { value: note },
        amount: { value: amount, isPrice: true },
        date: { value: date?.date, isDate: true },
        validity: { value: validity?.date, isDate: true },
        last_modified_date: { value: last_modified_date?.date, isDate: true },
      });

      return guaranteesItem;
    });
  }, []);

  const requestUrl = API_getBankGuarantees([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onSort,
      sort,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск банковских гарантий' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} onSort={onSort} sort={sort} fields={tableFields} data={filteredRecievedItems} modalType='guarantees' /> : null}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label='Добавить новую гарантию' onClick={showModal} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, [
    ...tableFields,
    { name: 'numeric[][]', label: 'Сумма', withoutPrefix: true },
  ]);

  const bankGuaranteesProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'note',
      'issued_by',
      'number',
      'amount',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditBankGuaranteeModal,
      name: 'new-bank-guarantees',
      title: 'Новая гарантия',
      props: { contractId },
    },

    prepareRecievedItemsFunction,

    requestUrl,

    EXPORT_MODULE_NAME: 'bank-guarantees',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...bankGuaranteesProps} />;
};

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default BankGuarantees;